<template>
  <router-link to="/">
    <svg
      id="logo"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <svg:style type="text/css">
        .st0 {
        fill: #ffc50b;
        }
        .st1 {
        fill: #d62127;
        }
      </svg:style>
      <circle class="st0" cx="50" cy="50" r="48.24" />
      <path
        class="st1"
        d="M65.17,21.33c-5.87,0-11.31,2.86-14.59,7.65l-0.68,0.86l-0.45-0.58c-3.24-4.97-8.7-7.93-14.62-7.93
	c-10.11,0-17.45,7.34-17.45,17.45c0,14.93,27.02,36.93,28.99,38.51c0.93,0.89,2.15,1.38,3.44,1.38c1.29,0,2.51-0.49,3.44-1.38
	c1.98-1.59,29.36-23.94,29.36-38.51C82.62,28.67,75.28,21.33,65.17,21.33z"
      />
      <g>
        <path
          d="M61.14,55.46c0.87,3.98-3.45,4.84-5.35,4.32s-5.78-0.6-5.78-0.6s-3.89,0.09-5.79,0.6c-1.9,0.52-6.23-0.35-5.36-4.32
		s4.67-3.8,5.71-9.17c1.04-5.36,5.45-5.02,5.45-5.02s4.4-0.35,5.44,5.02C56.48,51.65,60.28,51.48,61.14,55.46z M54,40.92
		c2.34,0.68,4.93-1.17,5.78-4.13c0.85-2.95-0.35-5.89-2.69-6.57c-2.34-0.68-4.93,1.17-5.78,4.13C50.45,37.31,51.66,40.25,54,40.92z
		 M63.7,39.85c-2.25-0.92-5.02,0.64-6.19,3.49c-1.16,2.85-0.28,5.9,1.98,6.82c2.25,0.92,5.02-0.64,6.19-3.49S65.96,40.77,63.7,39.85
		z M46,40.92c2.34-0.68,3.55-3.62,2.69-6.57s-3.44-4.8-5.78-4.12c-2.34,0.68-3.55,3.62-2.69,6.57C41.07,39.75,43.66,41.6,46,40.92z
		 M40.51,50.15c2.25-0.92,3.14-3.97,1.98-6.82c-1.16-2.84-3.93-4.41-6.19-3.49c-2.25,0.92-3.14,3.97-1.98,6.82
		C35.48,49.51,38.25,51.08,40.51,50.15z"
        />
      </g>
      <g>
        <path
          d="M36.94,67.44c-1.11-1.11-2.8-1.3-4.11-0.57V55.43c0-0.58-0.15-1.13-0.4-1.61c0.15-0.05,0.31-0.07,0.48-0.07
		c0.93,0,1.68,0.75,1.68,1.68v8.44c0,0.48,0.39,0.88,0.88,0.88c0.48,0,0.88-0.39,0.88-0.88v-8.44c0-1.89-1.54-3.43-3.43-3.43
		c-0.63,0-1.23,0.17-1.76,0.48C30.64,52.18,30.04,52,29.4,52c-1.89,0-3.43,1.54-3.43,3.43l0.01,16.11c0,0.74,0.27,1.77,0.63,2.41
		c0.01,0.01,0.01,0.02,0.02,0.03l10.11,16.38c0.21,0.38,0.4,1.11,0.4,1.54v3.21c0,0.48,0.39,0.88,0.88,0.88
		c0.48,0,0.88-0.39,0.88-0.88v-3.21c0-0.74-0.27-1.77-0.63-2.42c-0.01-0.01-0.01-0.02-0.02-0.03L28.14,73.09
		c-0.21-0.38-0.4-1.11-0.4-1.54l-0.01-16.11c0-0.93,0.75-1.68,1.68-1.68c0.93,0,1.68,0.75,1.68,1.68v14.43c0,0.02,0,0.04,0,0.06
		c0.02,0.86,0.35,1.71,1,2.37l7.86,7.86c0.34,0.34,0.9,0.34,1.24,0c0.34-0.34,0.34-0.9,0-1.24l-7.86-7.86
		c-0.65-0.65-0.65-1.72,0-2.37c0.65-0.65,1.72-0.65,2.37,0l9.68,9.68c1.08,1.08,1.99,3.28,1.99,4.81v11.96
		c0,0.48,0.39,0.88,0.88,0.88c0.48,0,0.88-0.39,0.88-0.88V83.16c0-1.99-1.1-4.65-2.51-6.05L36.94,67.44z"
        />
        <path
          d="M70.6,52c-0.64,0-1.24,0.18-1.75,0.48C68.32,52.17,67.71,52,67.09,52c-1.89,0-3.43,1.54-3.43,3.43v8.44
		c0,0.48,0.39,0.88,0.88,0.88s0.88-0.39,0.88-0.88v-8.44c0-0.93,0.75-1.68,1.68-1.68c0.16,0,0.33,0.02,0.48,0.07
		c-0.26,0.48-0.4,1.03-0.4,1.61v11.43c-1.31-0.73-3-0.54-4.11,0.57l-9.68,9.68c-1.41,1.41-2.51,4.06-2.51,6.05v11.96
		c0,0.48,0.39,0.88,0.88,0.88c0.48,0,0.88-0.39,0.88-0.88V83.16c0-1.53,0.91-3.73,1.99-4.81l9.68-9.68c0.65-0.65,1.72-0.65,2.37,0
		c0.65,0.65,0.65,1.72,0,2.37l-7.86,7.86c-0.34,0.34-0.34,0.9,0,1.24c0.34,0.34,0.9,0.34,1.24,0l7.86-7.86
		c0.65-0.65,0.99-1.51,1-2.37c0-0.02,0-0.04,0-0.06V55.43c0-0.93,0.75-1.68,1.68-1.68c0.93,0,1.68,0.75,1.68,1.68l-0.01,16.11
		c0,0.43-0.19,1.16-0.4,1.54L61.75,89.47c-0.01,0.01-0.01,0.02-0.02,0.03c-0.36,0.64-0.63,1.68-0.63,2.42v3.21
		c0,0.48,0.39,0.88,0.88,0.88c0.48,0,0.88-0.39,0.88-0.88v-3.21c0-0.43,0.19-1.16,0.4-1.54l10.11-16.38
		c0.01-0.01,0.01-0.02,0.02-0.03c0.36-0.64,0.63-1.68,0.63-2.41l0.01-16.11C74.03,53.54,72.49,52,70.6,52z"
        />
      </g>
    </svg>
  </router-link>
</template>
<script>
export default {
  name: 'Logo',
};
</script>
